import { UserInformations } from "utils/Types"


const PATHINFO = "/api/v1/Authenticate/infos?asUser=";
const UNAUTHORIZED_HTTP_CODE = 401;

export { getUserInfoAuth };

/**
 * Tente de récupérer les informations de l'utilisateur connecté. Si l'utilisateur n'est pas connecté (façon LeCerbère), la promesse
 * rejette avec un objet dont les champs sont vides.
 * @returns Informations de l'utilisateur connecté.
 */
const getUserInfoAuth = (asUser: string | null): Promise<UserInformations> => {
    const info: UserInformations = {
        IsAuthenticated: false,
        LastName: undefined,
        FirstName: undefined,
        LastNameAs: undefined,
        FirstNameAs: undefined,
        Email: undefined,
        UserId: 0,
        CompanyId: 0,
        CompanyCode: undefined,
        SessionId: undefined,
        SecretKey: undefined
    };

    return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        const pathWithParam = asUser !== null && asUser.length > 0 ? PATHINFO + asUser : PATHINFO;
        request.open("GET", pathWithParam, true);
        request.setRequestHeader("Content-Type", "application/json");
        request.responseType = "json";
        request.onreadystatechange = () => {
            // TDU - 2023-07-12 - Attention, le readyState pour un 401 ne passe jamais à 4 dans les navigateurs. Le handle onLoad n'est donc jamais appelé pour un code 401.
            // On est donc obligé pour ce code particulier de scruter le changement d'état.
            if (request.status == UNAUTHORIZED_HTTP_CODE)
                reject(info);
        };
        request.onload = () => {
            info.IsAuthenticated = request.response?.isAuthenticated ?? false;
            info.LastName = request.response?.lastName ?? undefined;
            info.FirstName = request.response?.firstName ?? undefined;
            info.Email = request.response?.email ?? undefined;
            info.UserId = request.response?.userId ?? 0;
            info.CompanyId = request.response?.companyId ?? 0;
            info.CompanyCode = request.response?.companyCode ?? undefined;
            info.FirstNameAs = request.response?.firstNameAs ?? undefined;
            info.LastNameAs = request.response?.lastNameAs ?? undefined;
            info.SessionId = request.response?.sessionId ?? undefined;
            info.SecretKey = request.response?.secretKey ?? undefined;

            resolve(info);
        };
        request.onabort = () => {
            reject(info);
        };
        request.onerror = () => {
            reject(info);
        };
        request.send(null);
    });

}

