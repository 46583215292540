import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import { Hfilters, HInputMultiSelect, Htable, Hrow, HsolutionTag, HstateTag, HBannerContext, Solutions, HSearchbar, Hdatepicker, BannerTypes, Choice } from 'hestia-component-library';
import { IClient, ISupplierOrder } from "../utils/Types";
import FileSaver from "file-saver";
import {
    GetFilesForOrder,
    GetOrderLines,
    GetSupplierClients,
    getSupplierOrdersResult,
    RerunSupplierOrder,
} from "../services/SupplierInterfaceApi";
import { currencyFormat, dateFormat, getActionIcon, getActionName, GetApplicationName, GetSolution, getStatusTag, IsWhiteLabel } from '../utils/Helpers';
import './orders.scss';
import { useSearchParams } from 'react-router-dom';
import { sendKpi } from 'services/Kpi';

/** Taille du bloc de chargement : nombre de ligne de commande chargées à la fois */
const CHUNKSIZE_LOADING: number = 100;

/**
 * Paramétres de recherche des commandes
 */
export interface IOrderSearchParameters {
    nbOrdersToLoad: number;
    offset: number;
    search: string | string[];
    sortColumn: number;
    sortOrder: boolean;
    isAReset: boolean;
}

/**
 * Type pour le filtre par date.
 */
export interface IDateFilter {
    dateRangeStart: string | null;
    dateRangeEnd: string | null | undefined;
}

/**
 * Page des commandes, avec la barre des recherches, les filtres et le tableau.
 * @returns Composant JSX
 */
export const Orders = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    /** Context, pour les notifications */
    const { addNotification } = useContext(HBannerContext);

    /** Hook Stats */
    const [supplierOrders, setSupplierOrders] = useState<ISupplierOrder[]>([]);
    const [clientFilters, setClientFilters] = useState<[Choice, Choice, Choice, Choice, ...Choice[]]>([{ label: "", value: false }, { label: "", value: false }, { label: "", value: false }, { label: "", value: false }]);
    const [statesFilter, setStatesFilter] = useState<[Choice, Choice, Choice, Choice, ...Choice[]]>([{ label: 'Générée', value: false }, { label: 'En cours', value: false }, { label: 'Echec envoi', value: false }, { label: 'Erreur', value: false }]);
    const [currentKey, setCurrentKey] = useState(0);
    const [totalOfCurrentResearch, setTotalOfCurrentResearch] = useState<number>(0);
    const [hasMoreOrdersToLoad, setHasMoreOrdersToLoad] = useState<boolean>(false);
    const [searchBarParameters, setSearchParameters] = useState<IOrderSearchParameters>({
        nbOrdersToLoad: CHUNKSIZE_LOADING,
        offset: 0,
        search: "",
        sortColumn: 0,
        sortOrder: false,
        isAReset: true
    });
    const [stateFilter, setStateFilter] = useState<string[]>([]);
    const [clientFilter, setClientFilter] = useState<string[]>([]);
    const [dateFilter, setDateFilter] = useState<IDateFilter>({
        dateRangeStart: "",
        dateRangeEnd: "",
    });


    /** Hook effects */
    useEffect(() => {
        document.title = "Commandes";
        loadClients();
    }, []);


    /** Chargement depuis un changement de filtre ou de recherche */
    useEffect(() => {
        loadOrders(
            searchBarParameters.nbOrdersToLoad,
            searchBarParameters.offset,
            searchBarParameters.search,
            searchBarParameters.sortColumn,
            searchBarParameters.sortOrder,
            searchBarParameters.isAReset,
            stateFilter,
            clientFilter,
            dateFilter.dateRangeStart,
            dateFilter.dateRangeEnd
        );
    }, [searchBarParameters, stateFilter, clientFilter, dateFilter]);

    /** Chargement du filtre clients, qui varie selon le fournisseur. */
    const loadClients = async () => {
        const result = (await GetSupplierClients(searchParams.has("as") ? searchParams.get("as") : null)) as IClient[];

        // TDU - 2023-11-16 - Contournement foireux : faire modifier la technique de paramètres minimum obligatoires (Hestia).
        let tab: [Choice, Choice, Choice, Choice, ...Choice[]] = [{ label: "", value: false }, { label: "", value: false }, { label: "", value: false }, { label: "", value: false }];
        for (let i = 0; i < 4; i++)
            tab.pop();
        let tabResult = result.map(x => { return { label: x.ClientCode, value: false } })
        for (let i = 0; i < tabResult.length; i++)
            tab.push(tabResult[i]);

        setClientFilters(tab);
    };

    /**
     * Charge les commandes
     * @param nbOrderLines Nombre de lignes de commande voulu
     * @param startingOffset Indexe de début
     * @param search Chaine à chercher
     * @param sortColumn Indexe de la colonne à trier
     * @param sortOrder Flag sur l'ordre de tri
     * @param resetOrders Flag pour savoir si on doit faire un reset après le chargement.
     * @param selectedSupplierOrderStates Filtres des états des commandes.
     * @param selectedClients Filtre sur les clients
     * @param dateRangeStart Date de début de création des commandes
     * @param dateRangeEnd Date de fin de création des commandes.
     * @returns Rien.
     */
    const loadOrders = (
        nbOrderLines: number,
        startingOffset: number,
        search: string | string[],
        sortColumn: number,
        sortOrder: boolean,
        resetOrders: boolean,
        selectedSupplierOrderStates: string[],
        selectedClients: string[],
        dateRangeStart: string | null,
        dateRangeEnd: string | null | undefined
    ) => {
        try {
            let newOrders: ISupplierOrder[] = [];
            const sortOrderValue = sortOrder ? 1 : 0;
            return GetOrderLines(
                searchParams.has("as") ? searchParams.get("as") : null,
                nbOrderLines,
                startingOffset,
                search,
                sortColumn,
                sortOrderValue,
                selectedSupplierOrderStates,
                selectedClients,
                dateRangeStart,
                dateRangeEnd,
            )
                .then((result) => {
                    result = result as getSupplierOrdersResult;
                    let newKey = currentKey + 1;
                    result.orders?.forEach((x) => {
                        x.Key = newKey.toString();
                        newKey = newKey + 1;
                    });
                    setCurrentKey(newKey);
                    setTotalOfCurrentResearch(result.total);
                    if (resetOrders) {
                        newOrders = result.orders;
                    }
                    else {
                        newOrders = [...supplierOrders, ...result.orders];
                    }

                    // Reste-t-il des données qui peuvent être chargée (pour l'infinity scroll) ?
                    setHasMoreOrdersToLoad(result.orders.length == nbOrderLines);

                    setSupplierOrders(newOrders);
                })
                .catch((error: ErrorEvent) => {
                    console.error("Une erreur est survenue lors du chargement des commandes.");
                    if ((error != null) && (error != undefined))
                        console.error(error);
                });
        }
        catch {
            console.error("Une erreur est survenue lors du chargement des commandes.");
        }
    };

    /**
     * Call To Action de l'utilisateur sur une ligne de commande.
     * @param action Numéro de l'action déclenchée
     * @param supplierOrderReference Référence de la commande fournisseur
     * @param applicationName Nom de l'application émettrice de la commande.
     * @returns Promesse
     */
    const ClickActionOnOrder = async (action: number, supplierOrderReference: string, applicationName: string) => {
        if (action == 0) {
            if (supplierOrders.filter(x => x.SupplierOrderReference === supplierOrderReference)[0].ElciaStatus === "En cours")
                return; //Eviter spam
            addNotification("Relance en cours...", BannerTypes.Information);
            setSupplierOrders((prevOrders => {
                const copy = [...prevOrders];
                const result = copy.map((order) => {
                    if (order.SupplierOrderReference === supplierOrderReference) {
                        return {
                            ...order,
                            ElciaStatus: "En cours",
                        };
                    }
                    return order;
                })
                return result;
            }));
            RerunSupplierOrder(
                supplierOrderReference,
                applicationName,
                searchParams.has("as") ? searchParams.get("as") : null
            )
                .then((result) => {
                    addNotification("Relance réussie", BannerTypes.Success);
                    setSupplierOrders((prevOrders => {
                        const copy = [...prevOrders];
                        const result = copy.map((order) => {
                            if (order.SupplierOrderReference === supplierOrderReference) {
                                return {
                                    ...order,
                                    ElciaStatus: "Générée",
                                };
                            }
                            return order;
                        })
                        return result;
                    }))
                })
                .catch((error: ErrorEvent) => {
                    addNotification("Une erreur est survenue", BannerTypes.Error);
                    setSupplierOrders((prevOrders => {
                        const copy = [...prevOrders];
                        const result = copy.map((order) => {
                            if (order.SupplierOrderReference === supplierOrderReference) {
                                return {
                                    ...order,
                                    ElciaStatus: "Echec envoi",
                                };
                            }
                            return order;
                        })
                        return result;
                    }))
                });
        }
        else {
            addNotification("Préparation des fichiers en cours...", BannerTypes.Information);
            const data = await GetFilesForOrder(
                supplierOrderReference,
                searchParams.has("as") ? searchParams.get("as") : null
            );
            try {
                if (data) {
                    addNotification("Les documents sont disponibles", BannerTypes.Success);
                    const blob = new Blob([data], { type: "application/zip" });
                    FileSaver.saveAs(blob, "commandes.zip");
                }
                else {
                    throw new Error();
                }
            }
            catch {
                addNotification("Une erreur est survenue", BannerTypes.Error);
            }
        }

    };

    /**
     * Action pour une recherche (searchbar) dans les commandes..
     * @param value Chaine recherchée.
     */
    const submitActionbar = (value: string | string[]) => {
        window.scrollTo(0, 0);
        setSearchParameters({
            nbOrdersToLoad: searchBarParameters.nbOrdersToLoad,
            offset: 0,
            search: value,
            sortColumn: searchBarParameters.sortColumn,
            sortOrder: searchBarParameters.sortOrder,
            isAReset: true
        });

        // KPI : "Clic sur filtre recherche"
        sendKpi("ClicFilterSearch")
            .then(response => { });
    };

    /**
     * Action de la recherche par date
     * @param startDate Date du début de la recherche, commandes créées à partir de cette date.
     * @param endDate Date de la fin de la recherche, commandes créées jusqu'à cette date.
     */
    const submitDateRangePicker = (startDate: Date | null, endDate: Date | null | undefined) => {
        window.scrollTo(0, 0);
        setDateFilter({
            dateRangeStart: startDate !== null ? startDate.toUTCString() : startDate,
            dateRangeEnd: endDate !== undefined ? endDate?.toUTCString() : endDate,
        });

        // KPI : "Clic sur filtre Date"
        sendKpi("ClicFilterDate")
            .then(response => { });
    };

    /**
     * Action de la recherche par état de commande.
     * @param value Etat de la commande.
     */
    const submitStateFilter = (value: string[]) => {
        window.scrollTo(0, 0);
        setStateFilter(value);

        // KPI : "Clic sur filtre états commande"
        sendKpi("ClicFilterOrderStats")
            .then(response => { });
    };

    /**
     * Action d'une recherche par client.
     * @param value Tableau de chaine représentant les clients à chercher.
     */
    const submitClientFilter = (value: string[]) => {
        window.scrollTo(0, 0);
        setClientFilter(value !== null && value.length > 0 ? value : []);

        // KPI : "Clic sur filtre clients"
        sendKpi("ClicFilterClients")
            .then(response => { });
    };

    /**
     * Action de tri par colonne.
     * @param index Indexe de la colonne à trier.
     * @param order Flag booléen pour l'ordre du tri.
     */
    const submitOrderColumn = (index: number, order: boolean) => {
        window.scrollTo(0, 0);
        setSearchParameters({
            nbOrdersToLoad: searchBarParameters.nbOrdersToLoad,
            offset: 0,
            search: searchBarParameters.search,
            sortColumn: index,
            sortOrder: order,
            isAReset: true
        });
    };

    const getSolutionTag = (solution: Solutions | string) => {
        if (Object.values<string>(Solutions).includes(solution)) {
            return <HsolutionTag solution={solution as Solutions} />
        }
        return <HsolutionTag customSolution={solution} />
    }

    /**
     * Donne les actions possibles selon la ligne de commande.
     * @param data Ligne de commande fournisseur.
     * @returns Tableau d'objet contenant un titre, une action au clic et une icône.
     */
    const getActions = (data: ISupplierOrder): any[] => {
        const title = getActionName(data.Actions);
        const onclick = () => ClickActionOnOrder(data.Actions, data.SupplierOrderReference, data.ApplicationName);
        const iconname = getActionIcon(data.Actions);
        const result: any[] = [];
        if (data.Actions !== 0 || !IsWhiteLabel(data.ApplicationName))
            result.push({ title: title, onClick: onclick, iconName: iconname });
        return result;
    }

    /** Affichage du composant */
    return (
        <>
            <div className="h-grid-container">
                <div className="h-grid-row">
                    <div className="h-grid-s-col-6 h-grid-m-col-12 h-grid-l-col-6 h-grid-xl-col-6 h-grid-xxl-col-6">
                        <HSearchbar submitOnClear
                                    onSubmit={(v, f) => { submitActionbar(v); }}
                                    title="Recherche"
                                    placeholder="Rechercher par Reference, nom du client, code client..."
                        />
                    </div>
                </div>
                <div className="h-grid-row">
                    <div className="h-grid-s-col-6 h-grid-m-col-12 h-grid-l-col-12 h-grid-xl-col-12 h-grid-xxl-col-12">
                        <Hfilters resultCount={totalOfCurrentResearch}
                                  onChange={(values) => {
                                    submitClientFilter(values[0]);
                                    submitStateFilter(values[1]);
                                    if (values[2] !== null) {
                                        submitDateRangePicker(values[2][0] as Date | null, values[2][1] as Date | null | undefined);
                                    }
                                    else {
                                        submitDateRangePicker(null, null);
                                    }
                                  }}>
                            <HInputMultiSelect title="Clients" values={clientFilters} allowHotReload placeholder={'Sélectionner un client'} />
                            <HInputMultiSelect title="Statut de la commande" values={statesFilter} placeholder={'Statut de la commande'} />
                            <Hdatepicker rangePicker timeRange title="Date" />
                        </Hfilters>
                    </div>
                </div>
                <div className="h-grid-row">
                    <div className="h-grid-s-col-6 h-grid-m-col-12 h-grid-l-col-12 h-grid-xl-col-12 h-grid-xxl-col-12">
                        <Htable headers={[
                            { label: 'Référence', isNumeric: false, sortable: true, size: 'xs' },
                            { label: 'Nom du client', isNumeric: false, sortable: true, size: 'm' },
                            { label: 'Code client', isNumeric: false, sortable: true, size: 'xs' },
                            { label: 'Date', isNumeric: false, sortable: true, size: 'xs' },
                            { label: 'Référence du devis', isNumeric: false, sortable: true, size: 'm' },
                            { label: 'Montant', isNumeric: true, sortable: true, size: 'xs' },
                            { label: 'Produits', isNumeric: true, sortable: true, size: 'xs' },
                            { label: 'Solution', isNumeric: false, sortable: true, size: 'xs' },
                            { label: 'Etat', isNumeric: false, sortable: true, size: 'xs' }]}
                            onSortAscending={(index) => {
                                submitOrderColumn(index, true);
                            }}
                            onSortDescending={(index) => {
                                submitOrderColumn(index, false);
                            }}
                            onSortReset={() => {
                                submitOrderColumn(-1, false);
                            }}
                            rowsHaveActions={true}
                            hasMoreItemsToLoad={hasMoreOrdersToLoad}
                            chunkSize={searchBarParameters.nbOrdersToLoad}
                            loadingMessage='Chargement des commandes...'
                            onLoadMore={(offset) => {
                                loadOrders(
                                    searchBarParameters.nbOrdersToLoad,
                                    offset,
                                    searchBarParameters.search,
                                    searchBarParameters.sortColumn,
                                    searchBarParameters.sortOrder,
                                    false,
                                    stateFilter,
                                    clientFilter,
                                    dateFilter.dateRangeStart,
                                    dateFilter.dateRangeEnd
                                )
                            }}>
                            {
                                supplierOrders.map((data, index) => (
                                    <Hrow
                                        key={data.SupplierOrderReference + index}
                                        data={[data.SupplierOrderReference,
                                        data.SupplierContextName,
                                        data.SupplierContextCode,
                                        dateFormat(data.CreationDate),
                                        data.QuotationReference,
                                        currencyFormat(data.TotalAmount, data.Currency),
                                        data.NumberOfProducts,
                                        Object.values<string>(Solutions).includes(GetApplicationName(data.ApplicationName)) ?
                                            <HsolutionTag key={data.SupplierOrderReference + index + "sol"} solution={GetSolution(data.ApplicationName)} />
                                            : <HsolutionTag key={data.SupplierOrderReference + index + "sol"} customSolution={GetApplicationName(data.ApplicationName)} />,
                                        <HstateTag key={data.SupplierOrderReference + index + "state"} type={getStatusTag(data.ElciaStatus)} value={data.ElciaStatus} />
                                        ]}
                                        actions={getActions(data)}
                                    />
                                ))}
                        </Htable>
                    </div>
                </div>
            </div>
        </>
    )
}