import * as React from 'react';
import axios from "axios";
import _ from 'lodash';

export type EntryConfig = {
    badgeValue?: string,
    children: EntryConfig[],
    description: string,
    isCurrent: boolean,
    isHidden: boolean,
    label: string,
    linkUrl: string,
    uiChildrenDisplayed: boolean,
    uiCode: string
}

export type UserApplication = {
    linkUrl?: string,
    key?: string,
    sourceLogo?: string,
    icon?: string,
    applicationKey?: string,
    securableKey?: string
}

export type LayoutConfig = {
    ContactUrl?: string,
    Entries?: EntryConfig[],
    HelpUrl?: string,
    LogOffUrl?: string,
    RedirectLogoUrl?: string,
    EulaUrl?: string,
    LegalNoticeUrl?: string,
    PrivacyUrl?: string,
    TechnicalAssistanceUrl?: string,
    UserApplications?: UserApplication[],
    UserMenuEntries?: EntryConfig[]
}


export async function getPortalLayout(token: string, asUser: string | null) {
    const response = await axios.get("/api/v1/Layout/layout?asUser=" + (asUser !== null && asUser.length > 0 ? asUser : ""), {
        params: {
            privateLayout: token,
        },
        headers: {
            "Content-Type": "application/json",
        },
    });
    const data = response.data;
    const result: LayoutConfig = _.mapKeys(data, (value, key) => _.upperFirst(key));
    return result;
}
