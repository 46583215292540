﻿import axios, { AxiosError } from "axios";
import { convertUnicode, getCurrencySymbol } from "../utils/Helpers";
import {
    ISupplierOrder,
    IClient,
} from "../utils/Types";

/** Type pour les filtres */
export interface ITotalByFilter {
    filter: string;
    count: number;
}

/** Type pour le retour de récupération de commandes. */
export interface getSupplierOrdersResult {
    orders: ISupplierOrder[];
    total: number;
}

/**
 * Appel le chargement de commandes fournisseurs, de façon pagginé.
 * @param nbOrderLines Nombre de ligne voulu.
 * @param offset Indexe de la recherche
 * @param search Chaine de recherche
 * @param sortColumn Indexe de la colonne à trier
 * @param sortOrder Ordre du tri
 * @param supplierFilter Chaine de filtre fournisseur.
 * @param clientFilter Filtre sur des clients
 * @param dateRangeStart Date de recherche de début de création des commandes.
 * @param dateRangeEnd Date de recherche de fin de création des commandes.
 * @returns Structure contenant les commandes et les infos de paggination.
 */
export const GetOrderLines = async (
    asUser: string | null,
    nbOrderLines: number,
    offset: number,
    search: string | string[],
    sortColumn?: number,
    sortOrder?: number,
    supplierFilter?: string[],
    clientFilter?: string[],
    dateRangeStart?: string | null,
    dateRangeEnd?: string | null | undefined,
) => {
    try {

        const response = await axios.get("/api/v1/SupplierOrder/list?asUser=" + (asUser !== null && asUser.length > 0 ? asUser : ""),
            {
                params: {
                    indexStart: offset,
                    number: nbOrderLines,
                    search: search,
                    sortColumn: sortColumn,
                    sortOrder: sortOrder,
                    supplierStateFilter: !supplierFilter?.includes("-1")
                        ? supplierFilter?.join()
                        : "",
                    clientCodeFilter: !clientFilter?.includes("-1")
                        ? clientFilter?.join()
                        : "",
                    dateRangeStart: dateRangeStart,
                    dateRangeEnd: dateRangeEnd,
                },
                headers: {
                    "Content-Type": "application/json"
                },
            });
        let totalRequest = 0;
        const supplierOrders: ISupplierOrder[] = [];
        // TDU - 2023/10/11 - Contrôle, car le retour en erreur ne passe pas forcément par le catch.
        if ((response != null) && (response != undefined) &&
            (response.data != null) && (response.data != undefined) &&
            (response.data.results != null) && (response.data.results != undefined) &&
            (response.data.total != null) && (response.data.total != undefined)) {
            const data = response.data;
            totalRequest = data.total;
            for (let i = 0; i < data.results.length; i++) {
                supplierOrders.push({
                    Key: data.results[i].supplierOrderReference + i,
                    SupplierOrderReference: data.results[i].supplierOrderReference,
                    ApplicationName: data.results[i].applicationName,
                    ElciaStatus: data.results[i].elciaStatus,
                    CreationDate: data.results[i].creationDate,
                    SupplierContextCode: data.results[i].supplierContextCode,
                    SupplierContextName: data.results[i].supplierContextName,
                    QuotationReference: convertUnicode(data.results[i].quotationReference),
                    NumberOfProducts: data.results[i].numberOfProducts,
                    TotalAmount: data.results[i].totalAmount,
                    Currency: getCurrencySymbol(data.results[i].currency),
                    Actions: data.results[i].elciaStatus === 'Générée' ? 1 : 0
                });
            }
        }
        const getOrderResult: getSupplierOrdersResult = {
            orders: supplierOrders,
            total: totalRequest,
        };
        return getOrderResult;
    }
    catch (error: unknown) {
        throw error as AxiosError;
    }
};

/**
 * Récupération des clients du fournisseur.
 * @returns Structure contenant les clients.
 */
export const GetSupplierClients = async (asUser: string | null) => {
    try {
        const response = await axios.get("/api/v1/SupplierOrder/clients?asUser=" + (asUser !== null && asUser.length > 0 ? asUser : ""), {
            params: {
            },
            headers: {
                "Content-Type": "application/json"
            },
        });
        const clients: IClient[] = [];
        // TDU - 2023/10/11 - Ajout de contrôle sur le retour en erreur.
        if ((response != null) && (response != undefined) &&
            (response.data != null) && (response.data != undefined)) {
            const data = response.data;
            for (let i = 0; i < data.length; i++) {
                clients.push({
                    ClientCode: data[i].clientCode,
                    ClientName: data[i].clientName
                });
            }
        }
        return clients;
    }
    catch (error: unknown) {
        throw error as AxiosError;
    }
};

/**
 * Téléchargement de fichiers d'une commande donnée.
 * @param supplierOrderReference Référence fournisseur
 * @returns Données sur le/les fichier/s à télécharger.
 */
export const GetFilesForOrder = async (
    supplierOrderReference: string,
    asUser: string | null
) => {
    try {
        const response = await axios.get("/api/v1.0/SupplierOrder/download?asUser=" + (asUser !== null && asUser.length > 0 ? asUser : ""), {
            params: {
                supplierOrderReference: supplierOrderReference,
            },
            headers: {
                "Content-Type": "application/json",
            },
            responseType: "arraybuffer",
        });
        let data = response.data;
        let arrayBufferConverted = "";
        new Uint8Array(data).forEach(function (byte: number) {
            arrayBufferConverted += String.fromCharCode(byte);
        });
        let jsonConverted;
        if (arrayBufferConverted.includes("errors")) {
            data = null;
            jsonConverted = JSON.parse(arrayBufferConverted);
            throw new Error(jsonConverted.errors[0].message);
        } else if (arrayBufferConverted.includes("status")) {
            data = null;
            jsonConverted = JSON.parse(arrayBufferConverted);
        }
        return data;
    }
    catch (error: unknown) {
        throw error as AxiosError;
    }
};

/**
 * Ré-exécution d'une commande fournisseur en erreur.
 * @param supplierOrderReference Référence de la commande fournisseur.
 * @param applicationName Nom de l'application émettrice de la commande.
 * @returns Données de la ré-exécution.
 */
export const RerunSupplierOrder = async (
    supplierOrderReference: string,
    applicationName: string,
    asUser: string | null
) => {
    try {
        const axiosInstance = axios.create();
        axiosInstance.interceptors.response.use(
            (response) => {
                return response.headers["content-type"] ===
                    "application/json; charset=utf-8"
                    ? response
                    : Promise.reject(response);
            },
            (error) => Promise.reject(error)
        );
        const response = await axiosInstance.post(
            "/api/v1.0/SupplierOrder/rerun?asUser=" + (asUser !== null && asUser.length > 0 ? asUser : ""),
            {
                supplierOrderReference: supplierOrderReference,
                applicationName: applicationName
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const data = response.data;
        if (data.isSuccessStatusCode === false) {
            throw new Error(data.reasonPhrase);
        }
        return data;
    } catch (error: unknown) {
        const e = error as ErrorEvent;
        throw e;
    }
};

