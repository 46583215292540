import { Solutions, StateTagTypes } from 'hestia-component-library';
import  clientApplicationConfig  from 'AppConfig.json';

export const getCurrencySymbol = (name: string) => {
    let value = "";
    switch (name) {
        case "EUR":
            value = "\u20AC";
            break;
    }
    return value;
};
export function convertUnicode(input) {
    return input.replace(/\\+u([0-9a-fA-F]{4})/g, (a, b) =>
        String.fromCharCode(parseInt(b, 16)));
}
export function currencyFormat(num, symbol) {
    const money = new Intl.NumberFormat('fr-FR',
        { style: 'currency', currency: 'EUR' }
    ).format(num);
    return money;
}
export function dateFormat(date) {
    const dateObject = new Date(date);
    const formattedDate = new Intl.DateTimeFormat('fr-FR', { dateStyle: 'short', timeStyle: 'medium', timeZone: 'Europe/Paris' }).format(dateObject);
    return formattedDate;
}
export const getActionName = (code: number) => {
    switch (code) {
        case 0:
            return "Relancer";
        case 1:
            return "Export document";
        default:
            return "Action"
    }
};

export const getActionIcon = (code: number) => {
    switch (code) {
        case 0:
            return "IconSystemReload";
        case 1:
            return "IconSystemDocument";
        default:
            return "IconNavigationCancel";
    }
}

export const getStatusTag = (status: string) => {
    switch (status) {
        case "G�n�r�e":
            return StateTagTypes.ACTIVE;
        case "En cours":
            return StateTagTypes.WAITING;
        case "Erreur":
        case "Echec envoi":
            return StateTagTypes.ERROR;
    }
}

export function GetIconNameFromLegoUiCode(uicode: string) {
    switch (uicode) {
        case "mypricers":
            return "IconSystemLibrary";
        case "customersaccess":
            return "IconSystemUser";
        case "mycollaborators":
            return "IconSystemCollaborator";
        case "supplierrequests":
            return "IconSystemAdressBook";
        case "settings":
            return "IconSystemSetting";
        case "myaccount":
            return "IconSystemAccount";
        case "managestatistics":
            return "IconSystemStats";
        case "oldcustomerarea":
            return "IconSystemBriefcase";
        case "managesponsoring":
            return "IconSystemInstall";
        case "myorders":
            return "IconSystemDelivery";
        default:
            return "";
    }
}

export function GetApplicationNameFromKey(key: string) {
    switch (key) {
        case "ApplicationProDevisStart":
            return "ProDevis Start";
        case "ApplicationMyPricer":
            return "MyPricer";
        default:
            return "";
    }
}

const whiteLabelApplications: string[] = [
    "Defiweb",
    "Adjustem",
    "DiapasonDevIsia",
    "Esopro",
    "ExtranetPrefal",
    "Flip",
    "Lola",
    "Komilfo",
    "Tryba"
]

export function IsWhiteLabel(applicationName: string): boolean {
    return whiteLabelApplications.includes(applicationName);
}

export function GetApplicationName(applicationName: string): string {
    return clientApplicationConfig.clientApplications.find(item => item.inputName === applicationName.toLowerCase().replace(/\s/g, ''))?.outputName ?? applicationName;
}

export function GetSolution(applicationName: string): Solutions {

    let appName: string = GetApplicationName(applicationName);

    let solutionApp = Object.keys(Solutions).find((value) => {
        return Solutions[value] == appName;
    });

    if (solutionApp == undefined)
        return Solutions.ElciaOnline;

    return Solutions[solutionApp];
}