import { FC, createContext, useContext, useState, useEffect } from "react";
import { BannerTypes, HBannerContext, envConfiguration } from 'hestia-component-library';
import { UserInformations } from "utils/Types";
import { getUserInfoAuth } from "services/AuthenticateAPI"
import * as React from 'react';
import { useSearchParams } from "react-router-dom";

// Utilisateur vide, lorsque 'on n'a pas encore des informations sur l'authentification.
const emptyUser: UserInformations = {
    IsAuthenticated: false,
    LastName: undefined,
    FirstName: undefined,
    LastNameAs: undefined,
    FirstNameAs: undefined,
    Email: undefined,
    UserId: 0,
    CompanyCode: undefined,
    CompanyId: 0,
    SessionId: undefined,
    SecretKey: undefined
};

// Création du contexte
const LeCerbereContext = createContext<{ context: UserInformations }>({
    context: emptyUser,
});


/**
 * Vérifie l'authentification par le cookie LeCerbere. Renvoi vers l'url de login si non authentifié.
 * @param param0
 * @returns Composant d'auth
 */
const Authentification: FC<{ children: any }> = ({ children }) => {

    const urlLogin: string = envConfiguration.configuration.LecerbereConfigUrl + envConfiguration.configuration.PortalUrl;

    const { addNotification } = useContext(HBannerContext);
    const [user, setUser] = useState<UserInformations>(emptyUser);
    const [searchParams, setSearchParams] = useSearchParams();

    // Authentification LeCerbere
    useEffect(() => {


        getUserInfoAuth(searchParams.has("as") ? searchParams.get("as") : null)
            .then((info) => {
                // Redirection
                if (!info.IsAuthenticated)
                    window.location.replace(urlLogin);

                setUser(info);
            }).catch((error) => {
                addNotification("Une erreur est survenue : " + error.toString(), BannerTypes.Error);
                window.location.replace(urlLogin);
            });
    }, []);

    if (user.IsAuthenticated == false)
        return (<></>);
    else
        return (
            <LeCerbereContext.Provider value={{
                context: user,
            }}>
                {children}
            </LeCerbereContext.Provider>
        );
}

export { Authentification, LeCerbereContext };
