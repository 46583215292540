import * as React from 'react';
import { Orders } from "./pages/Orders";
const AppRoutes = [
  {
    index: true,
    element: <Orders />,
  }
];

export default AppRoutes;
